@media (min-width: 768px) {
  .test-responsive {
    background-color: coral;
  }

  .as-column-md-3 {
    column-count: 3;
    /* Adjust the number of columns as needed */
    column-gap: 2rem;
    /* Adjust the gap between columns */
    orphans: 1;
    widows: 1;
  }

  .ratio-md-1x1 {
    padding-bottom: 100%;
  }

  .ratio-header {
    padding-bottom: 50%;
  }

  .rps-carousel {
    padding-bottom: 42.85%;
  }

  .rps-carousel h1 {
    font-size: 5rem;
  }
}