@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@300;400;500;600&display=swap');

:root {
  /* Breakpoint Variable */
  --breakpoint-xxl: '1400px';
  --breakpoint-xl: '1200px';
  --breakpoint-lg: '992px';
  --breakpoint-md: '768px';
  --breakpoint-sm: '576px';

  /* Color Variable */
  --color-primary: #002C44;
  --color-secondary: #BF9164;
  --color-tertiary: #C7C8CC;
  --color-black: #1E1E1E;
  --color-dark: #002C44;
  --color-gray: #C7C8CC;
  --color-light: #C7C8CC;
  --color-lighter: #C7C8CC;
  --color-white: #FFF;

  /* Font Variable */
  --font-header: 'Bodoni', sans-serif;
  --font-default: 'IBM Plex Sans Thai', sans-serif;
  --font-handwrite: 'IBM Plex Sans Thai', sans-serif;
}
