/* 
100	thin	      Thin or hairline font weight
200	extra-light	Extra Light font weight
300	light	Light font weight
400	normal	    Normal font weight (often the default)
500	medium	    Medium font weight
600	semi-bold	  Semi-Bold font weight
700	bold	      Bold font weight
800	extra-bold	Extra Bold font weight
900	black	      Black font weight 
*/

@font-face {
  font-family: 'Bodoni';
  src: url('./Fonts/BodoniModa18pt-Regular.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/BodoniModa18pt-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/BodoniModa18pt-Regular.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/BodoniModa18pt-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/BodoniModa18pt-Regular.svg#BodoniModa18pt-Regular') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Bodoni';
  src: url('./Fonts/BodoniModa18pt-Medium.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/BodoniModa18pt-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/BodoniModa18pt-Medium.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/BodoniModa18pt-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/BodoniModa18pt-Medium.svg#BodoniModa18pt-Medium') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Bodoni';
  src: url('./Fonts/BodoniModa18pt-Bold.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/BodoniModa18pt-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/BodoniModa18pt-Bold.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/BodoniModa18pt-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/BodoniModa18pt-Bold.svg#BodoniModa18pt-Bold') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Bodoni';
  src: url('./Fonts/BodoniModa18pt-ExtraBold.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/BodoniModa18pt-ExtraBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/BodoniModa18pt-ExtraBold.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/BodoniModa18pt-ExtraBold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/BodoniModa18pt-ExtraBold.svg#BodoniModa18pt-ExtraBold') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
}
