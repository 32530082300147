/* template */
body {
  background-color: var(--color-white);
}

#main-menu .logo {
  height: 40px;
}
#hero .logo {
  height: 60px;
}
#hero .carousel-caption {
  bottom: unset;
}
#footer .logo {
  height: 40px;
}

.offcanvas-title .logo {
  height: 40px;
}

.ratio-header {
  padding-bottom: 25%;
}

/* Component Article Display */
.HTML-Displayer .ql-size-large {
  font-family: var(--font-header);
  font-size: 1.2em;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.HTML-Displayer p {
  margin-bottom: 0;
  line-height: 1.2;
  font-weight: lighter;
}

.HTML-Displayer .question h4 {
  font-family: var(--font-header);
  font-weight: 400;
  color: var(--color-secondary);
}

.HTML-Displayer ul {
  margin-top: 0.75rem;
  margin-bottom: 0.50rem;
}

.HTML-Displayer ul li {
  font-weight: lighter;
  margin-bottom: 0.25rem;
}

.HTML-Displayer b {
  font-weight: bold;
}

.rps-carousel {
  padding-bottom: 100vh;
}

.rps-carousel h1 {
  font-size: 3rem;
}