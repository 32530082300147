@media (min-width: 992px) {
  .test-responsive {
    background-color: chocolate;
  }

  .as-column-lg-3 {
    column-count: 3;
    /* Adjust the number of columns as needed */
    column-gap: 2rem;
    /* Adjust the gap between columns */
    orphans: 1;
    widows: 1;
  }

  .ratio-lg-1x1 {
    padding-bottom: 100%;
  }

  .text-lg-white {
    color: white !important;
  }
}