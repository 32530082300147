.test-responsive {
  background-color: aquamarine;
}

/* font-family */
.font-default,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span {
  font-family: var(--font-default);
}

.font-header {
  font-family: var(--font-header);
}

.font-handwrite {
  font-family: var(--font-handwrite);
}

/* padding-bottom ratio */
.ratio-1x1 {
  padding-bottom: 100%;
}

.ratio-4x3 {
  padding-bottom: 75%;
}

.ratio-16x9 {
  padding-bottom: 56.25%;
}

.ratio-21x9 {
  padding-bottom: 42.85%;
}
.ratio-3x1 {
  padding-bottom: calc(100% / 3);
}

/* background */
.bg-img {
  background-color: var(--color-lighter);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-color-primary {
  background-color: var(--color-primary);
}

.bg-color-secondary {
  background-color: var(--color-secondary);
}

.bg-color-tertiary {
  background-color: var(--color-tertiary);
}

.bg-color-black {
  background-color: var(--color-black);
}

.bg-color-dark {
  background-color: var(--color-dark);
}

/* border */
.border-color-primary {
  border-color: var(--color-primary);
}

.border-color-secondary {
  border-color: var(--color-secondary);
}

.border-color-tertiary {
  border-color: var(--color-tertiary);
}

.border-color-black {
  border-color: var(--color-black);
}

.border-color-dark {
  border-color: var(--color-dark);
}

/* text */
.tx-color-primary {
  color: var(--color-primary);
}

.tx-color-secondary {
  color: var(--color-secondary);
}

.tx-color-tertiary {
  color: var(--color-tertiary);
}

.tx-lead-primary::first-letter {
  color: var(--color-primary) !important;
}

.tx-lead-secondary::first-letter {
  color: var(--color-secondary) !important;
}

.tx-spacing-05 {
  letter-spacing: 0.5px;
}

.tx-spacing-10 {
  letter-spacing: 1px;
}

.tx-spacing-15 {
  letter-spacing: 1.5px;
}

.tx-spacing-20 {
  letter-spacing: 2px;
}

.tx-spacing-25 {
  letter-spacing: 2.5px;
}

.tx-spacing-30 {
  letter-spacing: 3px;
}

/* layout */
.as-column-1 {
  column-count: 1;
  /* Adjust the number of columns as needed */
  column-gap: 2rem;
  /* Adjust the gap between columns */
  orphans: 1;
  widows: 1;
}
.as-column-3 {
  column-count: 3;
  /* Adjust the number of columns as needed */
  column-gap: 2rem;
  /* Adjust the gap between columns */
  orphans: 1;
  widows: 1;
}

.pin-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 16px;
  padding: 16px;
}

.pin-container .pin-card {
  width: 100%;
  height: auto;
}

.pin-container .card {
  background: transparent;
}

@media (max-width: 768px) {
  .pin-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .as-column-3 {
    column-count: 1;
    /* Adjust the number of columns as needed */
    column-gap: 2rem;
    /* Adjust the gap between columns */
    orphans: 1;
    widows: 1;
  }
}